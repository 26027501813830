.single {
  display: flex;
  width: 100%;

  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 50px;
        position: relative;

        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: 300;
              }
            }
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: rgb(94, 94, 94);
      margin-bottom: 20px;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    /* Dịch sang bên phải */
  }

  button {
    padding: 10px 20px;
    margin-bottom: 10px;
    /* Tạo khoảng cách giữa các nút */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .approveButton {
    background-color: #4caf50;
    color: white;
    margin-right: 10px;
    /* Tạo khoảng cách bên phải */
  }

  .rejectButton {
    background-color: #f44336;
    color: white;
  }

  button:hover {
    opacity: 0.8;
  }


}