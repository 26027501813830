@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Mooli:regular);

body {
  width: 100vw;
  overflow-x: hidden;
  height: auto;
  font-family: "Mooli", sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-display: swap;
}
