//
// _countdown.scss
//

/*********************************/
/*         Countdown             */
/*===============================*/
#countdown {
    .count-down {
        display: inline-block;
        margin: 30px 10px 10px;
        color: $white;
        text-align: center;
        .count-number {
            font-size: 40px;
            line-height: 95px;
        }
        .count-head {
            display: block;
            position: relative;
            transform: translateY(-25px);
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1px;
        }
    }
}

#countdown {
    .count-down {
        .count-number {
            font-size: 40px;
            line-height: 100px;
            height: 130px;
            width: 130px;
            border-radius: 10px;
            backdrop-filter: blur(5px);
            box-shadow: $shadow-lg;
            background-color: rgba($white, 0.1);
            border: 4px solid rgba($white, 0.1);
        }
    }
}

/*********************************/
/*         Maintenance           */
/*===============================*/
#maintenance {
    color: $white;
    font-size: 56px;
    letter-spacing: 1px;
    
    .indicator {
        font-size: 18px;
    }
}