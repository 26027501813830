//
// footer.scss
//

/*********************************/
/*         Footer                */
/*===============================*/
footer {
    position: relative;
    color: $gray-500;
    .footer-head {
        letter-spacing: 1px;         
        font-weight: 500;
        color: $light;
    }
    
    .text-foot {
        color: $gray-500;
    }

    .footer-list {
        margin-bottom: 0;
        li {
            margin-bottom: 10px;
            a {
                transition: all 0.5s ease;
                &:hover {
                    color: lighten($gray-500, 20%);
                }
            }   
            &:last-child{
                margin-bottom: 0;
            }         
        }
    }

    .footer-bar {
        border-top: 1px solid lighten($footer, 3%);
    }
}