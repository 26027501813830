.datatable {
  height: 650px;
  padding: 20px;

  .datatableTitle {
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: green;
        color: white;
      }
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }

    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }

    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton,
    .lockButton {
      color: #000;
      padding: 2px 5px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border: 1px solid transparent;

      &:hover {
        opacity: 0.8;
      }
    }

    .viewButton {
      color: darkblue;
      background-color: rgba(30, 144, 255, 0.1);
      border-color: rgba(30, 144, 255, 0.5);

      &:hover {
        background-color: rgba(30, 144, 255, 0.3);
      }
    }

    .lockButton {
      color: crimson;
      background-color: rgba(220, 20, 60, 0.1);
      border-color: rgba(220, 20, 60, 0.5);

      &:hover {
        background-color: rgba(220, 20, 60, 0.3);
      }
    }
  }
}